import { DateTime as d } from "luxon";

export function capitalizeFirstLetter(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export const getEndDateForBilling = (
  startDate: string,
  billingCycleName: string
) => {
  if (startDate) {
    if (billingCycleName === RATE_TYPES.WEEKLY) {
      return d.fromISO(startDate).plus({ weeks: 1});
    }
    if (billingCycleName === RATE_TYPES.MONTHLY) {
      return d.fromISO(startDate).plus({ months: 1});
    }
    if (billingCycleName === RATE_TYPES.DAILY) {
      return d.fromISO(startDate).plus({ days: 1});
    }
    return d.fromISO(startDate).plus({ hours: 1});
  }
  return d.now().plus({ hours: 1});
};



export enum RATE_TYPES {
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly"
}

export const rateTypes = [
  {
    rateTypeName: "hourly",
    rateTypeDuration: 60
  },
  {
    rateTypeName: "daily",
    rateTypeDuration: 1440
  },
  {
    rateTypeName: "weekly",
    rateTypeDuration: 10080
  },
  {
    rateTypeName: "monthly",
    rateTypeDuration: 43200
  }
];

export const billingCycles = [
  {
    billingCycleName: "none"
  },
  {
    billingCycleName: "weekly",
    billingCycleDuration: 10080
  },
  {
    billingCycleName: "monthly",
    billingCycleDuration: 43200
  }
];

export const customerTypes: any = [
  {
    value: "individual",
    label: "Individual"
  },
  {
    value: "business",
    label: "Business"
  }
];
export function getRentalPeriod(
  startDate: string,
  endDate: string,
  rateTypeDuration: number,
  rateTypeName: string
): string {
  let rentalPeriod = "";
  const timeDiff: number = d.fromISO(endDate).diff(d.fromISO(startDate)).toMillis();
  const rentalTime: number = Math.ceil(timeDiff / (rateTypeDuration * 60000));
  rentalPeriod = rentalTime + " ";
  switch (rateTypeName) {
    case "hourly":
      rentalPeriod += "hour(s)";
      break;
    case "daily":
      rentalPeriod += "day(s)";
      break;
    case "weekly":
      rentalPeriod += "week(s)";
      break;
    case "monthly":
      rentalPeriod += "month(s)";
      break;
  }
  return rentalPeriod;
}

export enum Constants {
  MINUTE_TO_MILLISECOND = 60000,
  BOOKING_ADVANCE_START_TIME = 5 // in minutes
}

export enum BookingFlowStage {
  BASE_DETAILS,
  ADDONS_SELECTION,
  PERSONAL_DETAILS,
  QUOTE_VIEW,
  PAYMENT_VIEW
}

export const addonTypes = ["INSURANCE", "CUSTOM", "VALET"];

export enum BookingStatus {
  IN_PROGRESS = "IN_PROGRESS"
}

export enum BookingStatusText {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMATION_IN_PROCESS = "CONFIRMATION IN PROCESS",
  CONFIRMED = "CONFIRMED",
  IN_PROGRESS = "IN PROGRESS",
  QUOTE = "QUOTE",
  DRAFT = "DRAFT",
  REQUIRES_APPROVAL = "REQUIRES APPROVAL"
}

export enum SOCKET_EVENTS {
  REQUEST_VIEW_BOOKING = "REQUEST_VIEW_BOOKING",
  GET_UPDATED_BOOKING = "GET_UPDATED_BOOKING",
  RENTAL_AGGREMENT_GENERATED = "RENTAL_AGGREMENT_GENERATED",
  REQUEST_VIEW_INVOICE = "REQUEST_VIEW_INVOICE",
  REQUEST_VIEW_CUSTOMER = "REQUEST_VIEW_CUSTOMER",
  GET_UPDATED_CUSTOMER = "GET_UPDATED_CUSTOMER",
}